<template>
  <div class="signalHistoricalProfitH5">
    <div class="bscroll" ref="bscroll">
      <div class="bscroll-container">
        <!-- 筛选按钮 -->
        <div class="mobileFilterDiv" v-if="mobileFlag">
          <el-button
            class="mobileFilterBtn"
            @click="mobileSearchDrawer = true"
            type="text"
            size="small"
          >
            {{ $t("mobileFilterBtnTxt") }}
            <svg-icon class="greenIcon" icon-class="MobileFilterBtn"></svg-icon>
          </el-button>
        </div>
        <div class="mobileTableList" v-show="mobileFlag">
          <el-collapse accordion @change="collapseChange">
            <el-collapse-item v-for="(item, index) in tableData" :key="index">
              <template slot="title">
                <!-- 直接展示的字段 -->
                <div class="mobileTableCard" @click.stop="showCollapse()">
                  <!-- 开始时间 -->
                  <div class="mobileTableItem">
                    <p class="mobileTableTitle">
                      {{ $t("startSelectTime") }}
                    </p>
                    <p class="mobileTableContent">
                      {{ item.settlementBeginTime }}
                    </p>
                  </div>
                  <!-- 结算时间 -->
                  <div class="mobileTableItem">
                    <p class="mobileTableTitle">
                      {{ $t("settleSelectTime") }}
                    </p>
                    <p class="mobileTableContent">
                      {{ item.settlementEndTime }}
                    </p>
                  </div>
                  <!-- 获得分润 -->
                  <div class="mobileTableItem">
                    <p class="mobileTableTitle">
                      {{ $t("getProfitShare") }}
                    </p>
                    <p class="mobileTableContent">
                      {{ formatNumberFractionDigits(item.profit) }}
                    </p>
                  </div>
                  <!-- 分润订单号 -->
                  <div class="mobileTableItem">
                    <p class="mobileTableTitle">
                      {{ $t("profitSharingOrderNumber") }}
                    </p>
                    <p class="mobileTableContent">
                      {{ item.tmdFinanceFlowId || "-" }}
                    </p>
                  </div>
                </div>
              </template>
              <!-- 隐藏的字段（展开才能看到） -->
              <div class="mobileTableCard">
                <!-- 客户账号 -->
                <div class="mobileTableItem">
                  <p class="mobileTableTitle">
                    {{ $t("MyFollowers_CopyAccount") }}
                  </p>
                  <p class="mobileTableContent">
                    {{ item.followLoginId }}
                  </p>
                </div>
                <!-- 客户MT4账号 -->
                <div class="mobileTableItem">
                  <p class="mobileTableTitle">
                    {{ $t("customerMt4Account") }}
                  </p>
                  <p class="mobileTableContent">
                    {{ item.followAccount }}
                  </p>
                </div>
                <!-- 期初净值 -->
                <div class="mobileTableItem">
                  <p class="mobileTableTitle">
                    {{ $t("InitialNetValue") }}
                  </p>
                  <p class="mobileTableContent">
                    {{ formatNumberFractionDigits(item.beginPeriodEquity) }}
                  </p>
                </div>
                <!-- 本期入金 -->
                <div class="mobileTableItem">
                  <p class="mobileTableTitle">
                    {{ $t("CurrentDeposit") }}
                  </p>
                  <p class="mobileTableContent">
                    {{ formatNumberFractionDigits(item.currentPeriodDeposit) }}
                  </p>
                </div>
                <!-- 结算净值 -->
                <div class="mobileTableItem">
                  <p class="mobileTableTitle">
                    {{ $t("NetSettlementValue") }}
                  </p>
                  <p class="mobileTableContent">
                    {{
                      formatNumberFractionDigits(item.settlementPeriodEquity)
                    }}
                  </p>
                </div>
                <!-- 本期交易量 -->
                <div class="mobileTableItem">
                  <p class="mobileTableTitle">
                    {{ $t("CurrentTradingVolume") }}
                  </p>
                  <p class="mobileTableContent">
                    {{
                      `${formatNumberFractionDigits(
                        item.currentPeriodVolumes
                      )} lots`
                    }}
                  </p>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div class="x" v-if="up && tableData.length < total">
            <i class="el-icon-loading"></i>
            {{ $t("loading") }}
          </div>
          <div class="x" v-if="!up && tableData.length >= total">
            {{ $t("noMoreData") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 筛选抽屉 -->
    <el-drawer
      class="mobileSearchDrawer"
      :visible.sync="mobileSearchDrawer"
      direction="ttb"
    >
      <div class="mobileFilterReturnBtnDiv">
        <el-button
          class="mobileFilterReturnBtn"
          @click="mobileSearchDrawer = false"
        >
          {{ $t("MyFollowers_Search") }}
          <i class="el-icon-arrow-up"></i>
        </el-button>
      </div>

      <el-form
        label-position="top"
        :model="tableSearchForm"
        ref="tableSearchForm"
      >
        <!-- 开始时间 -->
        <el-form-item :label="$t('startSelectTime')" prop="settleBeginTime">
          <el-date-picker
            v-model="tableSearchForm.settleBeginTime"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 结算时间 -->
        <el-form-item :label="$t('settleSelectTime')" prop="settleEndTime">
          <el-date-picker
            v-model="tableSearchForm.settleEndTime"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 客户MT4账号 -->
        <el-form-item :label="$t('customerMt4Account')" prop="followAccount">
          <el-input
            v-model="tableSearchForm.followAccount"
            maxlength="40"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 客户账号 -->
        <el-form-item :label="$t('MyFollowers_CopyAccount')" prop="loginId">
          <el-input
            v-model="tableSearchForm.loginId"
            maxlength="40"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="mobileFilterBottomBtns">
        <el-button class="resetFormBtn" @click="reset()">
          {{ $t("MyFollowers_Reset") }}
        </el-button>
        <el-button class="serchFormBtn" @click="getData(1, pageSize)">
          {{ $t("MyFollowers_Search") }}
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
import { concatTableData } from "@/plugins/common.js";
import { mapState } from "vuex";
import BScroll from "@better-scroll/core";
import Pullup from "@better-scroll/pull-up";

BScroll.use(Pullup);

export default {
  name: "SignalHistoricalProfitH5",
  computed: {
    ...mapState(["checkPortfolio", "mobileFlag"]),
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      pageSize: 5,
      tableSearchForm: {
        settleBeginTime: null,
        settleEndTime: null,
        followAccount: "",
        loginId: "",
      },
      mobileSearchDrawer: false,
      up: false,
    };
  },
  created() {
    if (this.$route.query.account) {
      this.tableSearchForm.followAccount = this.$route.query.account;
    }
    this.getData(1, 5);
  },
  methods: {
    formatNumberFractionDigits,
    collapseChange() {
      this.$nextTick(() => {
        if (!this.firstOpen) {
          this.scroll.maxScrollY = this.scroll.maxScrollY - 150;
          this.firstOpen = true;
        } else {
          this.scroll.maxScrollY = this.scroll.maxScrollY + 150;
          this.firstOpen = false;
        }
      });
    },
    scrollFn() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.bscroll, {
            click: true,
            scrollY: true,
            probeType: 3,
          });
        } else {
          this.scroll.refresh();
        }

        //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
        this.scroll.on("touchEnd", (pos) => {
          //上拉加载 总高度>下拉的高度+10 触发加载更多
          if (this.scroll.maxScrollY > pos.y + 50) {
            this.up = true;
            setTimeout(() => {
              //使用refresh 方法 来更新scroll  解决无法滚动的问题
              if (this.tableData.length < this.total) {
                this.currentPage = this.currentPage + 1;
                this.getData(this.currentPage, this.pageSize, "noRefresh");
                this.scroll.refresh();
                this.up = false;
              } else {
                this.up = false;
              }
            }, 1000);
          }
        });
      });
    },
    getData(pageIndex, pageSize, tag) {
      if (!this.mobileFlag || tag !== "noRefresh") {
        this.tableData = [];
      }

      const { settleBeginTime, settleEndTime, followAccount, loginId } =
        this.tableSearchForm;
      const params = {
        type: 1,
        portfolioId: this.checkPortfolio.id,
        settleBeginTime: settleBeginTime,
        settleEndTime: settleEndTime,
        followAccount: followAccount ? followAccount.trim() : undefined,
        loginId: loginId ? loginId.trim() : undefined,
        pageIndex: pageIndex,
        pageSize: pageSize,
      };
      this.$axios
        .post("/api/ShareProfit/GetSignalShareProfitPage", params)
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.total = dt.result.total;
            if (this.mobileFlag && tag == "noRefresh") {
              dt.result.pageList.forEach((item) => {
                this.tableData.push(item);
              });
            } else {
              this.tableData = concatTableData(
                dt.result.pageList,
                dt.result.total,
                this.currentPage,
                this.pageSize
              );
            }

            this.fullscreenLoading = false;
            if (this.mobileFlag) {
              this.scrollFn();
            }
          }
        })
        .finally(() => {
          this.mobileSearchDrawer = false;
        });
    },
    reset() {
      this.$router.replace({ query: {} });
      this.$refs.tableSearchForm.resetFields();
      this.tableSearchForm.followAccount = "";
      this.getData(this.currentPage, this.pageSize);
    },
    refresh() {
      window.location.reload();
    },
  },
};
</script>
<style lang="less" scoped>
.signalHistoricalProfitH5 {
  .portfolioClick {
    cursor: pointer;
    color: rgb(59, 158, 238);
  }
  .label-box {
    justify-content: flex-start;
    .labelIconTxt {
      width: 240px;
      right: -28%;
    }
  }
  .formPercent {
    right: 22%;
  }
  .tableSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      margin-top: 24px;
    }
  }
}
@media screen and (max-width: 767px) {
  .PageCommon {
    .pageTitle {
      justify-content: flex-end;
    }
  }

  .confirmDialogBtn {
    padding: 8px 10px;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>
