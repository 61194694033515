import {
  Form,
  FormItem,
  Input,
  Row,
  Col,
  Tooltip,
  Button,
  Select,
  Option,
  Dialog,
} from "element-ui";
import { mapState } from "vuex";
import { omit } from "lodash";
import isMobile from "@/shared/utils/isMobile";
import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
import styles from "./index.less?module";

const SignalBaseInfo = {
  data() {
    return {
      formVal: {
        portfolioName: "",
        shareProfitPercent: null,
        settlementPeriodType: null,
        followDirection: "",
        createTime: "",
        currentSettlementBeginTime: "",
        currentSettlementEndTime: "",
        account: "",
        serverName: "",
        equity: null,
        balance: null,
        profit: "",
        loginStatus: null,
      },
      enableStatus: 0,
      enableControlStatus: 0,
      switchLoading: false,
      shareProfitPercentDisabled: true,
      detailInfo: {},
      settlementPeriodTypeStatus: 1,
      shareProfitPercentStatus: 1,
      isDisabledDialog: false,
      settleUnbindForm: {
        currentBeginTime: "",
        followUserCount: null,
        sharePercent: null,
        shareTotalProfit: null,
      },
    };
  },
  computed: {
    ...mapState(["checkPortfolio"]),
  },
  created() {
    this.getBaseInfo();
  },
  methods: {
    formatNumberFractionDigits,
    // 获取信号源基本信息
    getBaseInfo() {
      const loading = this.$loading({
        lock: true,
      });
      this.$axios
        .get("/api/Portfolio/GetPortfolioDetail", {
          params: { id: this.checkPortfolio.id },
        })
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.detailInfo = dt.result;
            this.formVal = omit(dt.result, [
              "settlementPeriodTypeTitle",
              "deleteBtnStatus",
              "enableControlStatus",
              "status",
              "followDirection",
            ]);
            this.enableStatus = dt.result.status;
            this.enableStatusTxt = dt.result.status ? "启用中" : "已禁用";
            this.formVal.account = dt.result.mainAccountInfo.account;
            this.formVal.serverName = dt.result.mainAccountInfo.serverName;
            this.formVal.equity = dt.result.mainAccountInfo.equity;
            this.formVal.balance = dt.result.mainAccountInfo.balance;
            this.formVal.profit = formatNumberFractionDigits(
              dt.result.mainAccountInfo.profit,
              { usePlus: true }
            );
            this.formVal.loginStatus = dt.result.mainAccountInfo.loginStatus;
            this.formVal.followDirection = dt.result.followDirection
              ? this.$t("MyFollowers_ReverseCopy")
              : this.$t("MyFollowers_Copy");
            this.enableControlStatus = dt.result.enableControlStatus;
            this.settlementPeriodTypeStatus =
              dt.result.settlementPeriodTypeStatus;
            this.shareProfitPercentStatus = dt.result.shareProfitPercentStatus;
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    // 分润比例：编辑、保存
    handleShareProfitPercentCLick() {
      this.$refs.signalBaseInfoForm.validateField(
        "shareProfitPercent",
        (errorMessage) => {
          if (!errorMessage) {
            this.$axios
              .post("/api/Portfolio/InitShareProfitPercent", {
                id: this.checkPortfolio.id,
                shareProfitPercent: +this.formVal.shareProfitPercent,
              })
              .then((res) => {
                let dt = res.data;
                if (dt.isSuccess) {
                  this.$notify.success({
                    title: this.$t("nofity_success"),
                    message: this.$t("MyFollowers_operateSuccess"),
                  });
                  this.getBaseInfo();
                } else {
                  this.$notify.error({
                    title: this.$t("MyFollowers_prompt"),
                    message: dt.result,
                  });
                }
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        }
      );
    },
    // 结算周期：编辑、保存
    handleSettlementPeriodTypeTitleCLick() {
      this.$refs.signalBaseInfoForm.validateField(
        "settlementPeriodType",
        (errorMessage) => {
          if (!errorMessage) {
            this.$axios
              .post("/api/Portfolio/InitShareProfitPeriod", {
                id: this.checkPortfolio.id,
                settlementPeriodType: +this.formVal.settlementPeriodType,
              })
              .then((res) => {
                let dt = res.data;
                if (dt.isSuccess) {
                  this.$notify.success({
                    title: this.$t("nofity_success"),
                    message: this.$t("MyFollowers_operateSuccess"),
                  });
                  this.getBaseInfo();
                } else {
                  this.$notify.error({
                    title: this.$t("MyFollowers_prompt"),
                    message: dt.result,
                  });
                }
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        }
      );
    },
    cancelSignal() {
      this.isDisabledDialog = false;
    },
    settleUnbind() {
      const loading = this.$loading({
        lock: true,
      });
      this.$axios
        .get(
          "/Api/Portfolio/SettltementPortfolioDetail?id=" +
            this.checkPortfolio.id
        )
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.isDisabledDialog = true;
            this.settleUnbindForm = dt.result;
            this.settleUnbindForm.shareTotalProfit = formatNumberFractionDigits(
              this.settleUnbindForm.shareTotalProfit
            );
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    settleUnbindSubmit() {
      this.$confirm(
        this.$t("settleUnbindConfirmTipsSignal"),
        this.$t("MyFollowers_prompt"),
        {
          confirmButtonText: this.$t("MyFollowers_confirm"),
          cancelButtonText: this.$t("MyFollowers_Cancel"),
          type: "warning",
        }
      ).then(() => {
        const loading = this.$loading({
          lock: true,
        });
        this.$axios
          .get(
            "/Api/Portfolio/SettltementPortfolio?id=" + this.checkPortfolio.id
          )
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({
                title: this.$t("nofity_success"),
                message: this.$t("MyFollowers_operateSuccess"),
              });
              this.getBaseInfo();
              this.isDisabledDialog = false;
            } else {
              this.$notify.error({
                title: this.$t("MyFollowers_prompt"),
                message: dt.result,
              });
            }
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    // 重新登录
    handleReRegister() {
      this.$router.replace({ path: "/Login" });
    },
  },

  render() {
    const billingCycleOptions = [
      { label: this.$t("week"), value: 2 },
      { label: this.$t("month"), value: 3 },
      { label: this.$t("quarter"), value: 4 },
      { label: this.$t("halfAYear"), value: 5 },
    ];

    // ‘连接状态’的映射
    const loginStatusMap = {
      1: this.$t("connectionFail"),
      0: this.$t("connectionSuccess"),
    };

    const colSpan = isMobile ? 24 : 12;

    return (
      <div class={{ tableDiv: !isMobile, [styles.h5Container]: isMobile }}>
        <Form
          ref="signalBaseInfoForm"
          labelPosition="top"
          props={{ model: this.formVal }}
          class={styles.signalBaseInfoForm}
        >
          {/* --------------------------信号设置-------------------------- */}
          <div class={styles.formGroupTitle}>
            <div class={styles.diamondIcon} />
            {this.$t("signalSetting")}
          </div>
          <Row gutter={30}>
            {/* 名称 */}
            <Col span={colSpan}>
              <FormItem label={this.$t("name")} prop="portfolioName">
                <Input v-model={this.formVal.portfolioName} disabled />
              </FormItem>
            </Col>
            {/* 分润比例 */}
            <Col span={colSpan}>
              <FormItem
                label={this.$t("profitSharingRatio")}
                prop="shareProfitPercent"
                rules={{
                  validator: (_, value, callback) => {
                    if (!`${value}`) {
                      return callback(
                        new Error(this.$t("PaymentOfDividendsNotNull"))
                      );
                    } else {
                      var reg = /^([0]$)|(^[1-9][0-9]{0,1}|100)$/;
                      if (!reg.test(value)) {
                        callback(new Error(this.$t("numberOneToHundred")));
                      } else {
                        callback();
                      }
                    }
                  },
                }}
              >
                <div class={styles.editFormItemWrapper}>
                  <Input
                    v-model={this.formVal.shareProfitPercent}
                    disabled={this.shareProfitPercentStatus == 1}
                    placeholder="0-100"
                  >
                    <template slot="suffix">%</template>
                  </Input>
                  {!this.shareProfitPercentStatus && (
                    <Button
                      type="text"
                      class={styles.editButton}
                      onClick={this.handleShareProfitPercentCLick}
                    >
                      {this.shareProfitPercentStatus
                        ? this.$t("edit")
                        : this.$t("MyFollowers_Save")}
                    </Button>
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            {/* 结算周期 */}
            <Col span={colSpan}>
              <FormItem
                prop="settlementPeriodType"
                rules={{
                  required: true,
                  message: this.$t("settlementPeriodTypeNotNull"),
                }}
                class={styles.billingCycleFormItem}
              >
                <div slot="label" class={styles.billingCycleLabel}>
                  <span>{this.$t("billingCycle")}</span>
                  <Tooltip
                    content={this.$t("basedOnCalendarDays")}
                    placement="right"
                  >
                    <svg-icon class="labelIcon" icon-class="LabelIcon" />
                  </Tooltip>
                </div>
                <div class={styles.editFormItemWrapper}>
                  <Select
                    v-model={this.formVal.settlementPeriodType}
                    disabled={this.settlementPeriodTypeStatus == 1}
                  >
                    {billingCycleOptions.map((itemOption) => (
                      <Option
                        key={itemOption.value}
                        label={itemOption.label}
                        value={itemOption.value}
                      />
                    ))}
                  </Select>
                  {!this.settlementPeriodTypeStatus && (
                    <Button
                      type="text"
                      class={styles.editButton}
                      onClick={this.handleSettlementPeriodTypeTitleCLick}
                    >
                      {this.settlementPeriodTypeStatus
                        ? this.$t("edit")
                        : this.$t("MyFollowers_Save")}
                    </Button>
                  )}
                </div>
              </FormItem>
            </Col>
            {/* 跟单方向 */}
            <Col span={colSpan}>
              <FormItem
                label={this.$t("MyFollowers_CopyDirection")}
                prop="followDirection"
              >
                <Input v-model={this.formVal.followDirection} disabled />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            {/* 创建时间 */}
            <Col span={colSpan}>
              <FormItem label={this.$t("echarts_createtime")} prop="createTime">
                <Input v-model={this.formVal.createTime} disabled />
              </FormItem>
            </Col>
            {/* 本期开始时间 */}
            <Col span={colSpan}>
              <FormItem
                label={this.$t("startTimeOfThisPeriod")}
                prop="currentSettlementBeginTime"
              >
                <Input
                  v-model={this.formVal.currentSettlementBeginTime}
                  disabled
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            {/* 本期结算时间 */}
            <Col span={colSpan}>
              <FormItem
                label={this.$t("settlementTimeOfThisPeriod")}
                prop="currentSettlementEndTime"
              >
                <Input
                  v-model={this.formVal.currentSettlementEndTime}
                  disabled
                />
              </FormItem>
            </Col>

            {/* 启用状态 */}
            <Col span={colSpan}>
              <FormItem label={this.$t("enableStatus")}>
                <div class={styles.editFormItemWrapper}>
                  <Input v-model={this.enableStatusTxt} disabled />
                  {!!this.enableStatus && (
                    <Button
                      type="text"
                      class={styles.editButton}
                      onClick={this.settleUnbind}
                    >
                      {this.$t("disabledDialogTitle")}
                    </Button>
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            {/* 禁用时间 */}
            {!this.enableStatus && (
              <Col span={colSpan}>
                <FormItem label={this.$t("signalOffTime")} prop="updateTime">
                  <Input v-model={this.formVal.updateTime} disabled />
                </FormItem>
              </Col>
            )}
          </Row>
          {/* 分割线 */}
          <div class={styles.dividerLine} />
          {/* --------------------------信号账号-------------------------- */}
          <div class={styles.formGroupTitle}>
            <div class={styles.diamondIcon} />
            {this.$t("signalAccount")}
          </div>
          <Row gutter={30}>
            {/* 信号账户 */}
            <Col span={colSpan}>
              <FormItem
                label={this.$t("MyFollowers_MasterAccountList")}
                prop="account"
              >
                <Input v-model={this.formVal.account} disabled />
              </FormItem>
            </Col>
            {/* 服务器 */}
            <Col span={colSpan}>
              <FormItem label={this.$t("server")} prop="serverName">
                <Input v-model={this.formVal.serverName} disabled />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            {/* 净值 */}
            <Col span={colSpan}>
              <FormItem label={this.$t("MyFollowers_Equity")} prop="equity">
                <Input v-model={this.formVal.equity} disabled />
              </FormItem>
            </Col>
            {/* 余额 */}
            <Col span={colSpan}>
              <FormItem label={this.$t("MyFollowers_Balance")} prop="balance">
                <Input v-model={this.formVal.balance} disabled />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            {/* 盈亏 */}
            <Col span={colSpan}>
              <FormItem label={this.$t("MyFollowers_PL")} prop="profit">
                <Input
                  v-model={this.formVal.profit}
                  disabled
                  class={[
                    this.formVal.profit > 0
                      ? "greenNum"
                      : this.formVal.profit < 0
                      ? "redNum"
                      : "",
                    "mobileTableContent",
                  ]}
                />
              </FormItem>
            </Col>
            {/* 连接状态 */}
            <Col span={colSpan}>
              <FormItem label={this.$t("connectionStatus")} prop="loginStatus">
                <div class={styles.editFormItemWrapper}>
                  <Input
                    value={loginStatusMap[this.formVal.loginStatus]}
                    disabled
                    class={
                      this.formVal.loginStatus === 0 ? "greenNum" : "redNum"
                    }
                  />
                  {!!this.formVal.loginStatus && (
                    <Button
                      type="text"
                      class={styles.editButton}
                      onClick={this.handleReRegister}
                    >
                      {this.$t("reRegister")}
                    </Button>
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>

        <Dialog
          title={this.$t("disabledDialogTitle")}
          visible={this.isDisabledDialog}
          close-on-click-modal={false}
          width="40%"
          top="5vh"
          onClose={this.cancelSignal}
        >
          <Form
            ref="settleUnbindForm"
            labelPosition={isMobile ? "top" : "left"}
            props={{ model: this.settleUnbindForm }}
            class={styles.signalBaseInfoForm}
            label-width="150px"
          >
            {/* 本期开始时间 */}
            <FormItem
              label={this.$t("startTimeOfThisPeriod")}
              prop="currentBeginTime"
            >
              <Input
                v-model={this.settleUnbindForm.currentBeginTime}
                disabled
              />
            </FormItem>
            {/* 跟随客户 */}
            <FormItem label={this.$t("followCustomNum")} prop="followUserCount">
              <Input v-model={this.settleUnbindForm.followUserCount} disabled />
            </FormItem>
            {/* 分润比例 */}

            <FormItem label={this.$t("profitSharingRatio")} prop="sharePercent">
              <Input v-model={this.settleUnbindForm.sharePercent} disabled />
              <p class={styles.sharePercentIcon}>%</p>
            </FormItem>
            {/* 分润金额 */}
            <FormItem label={this.$t("shareMoneyNum")} prop="shareTotalProfit">
              <Input
                v-model={this.settleUnbindForm.shareTotalProfit}
                disabled
              />
            </FormItem>
          </Form>
          {this.settleUnbindForm.settlementStatus == 1 && (
            <p class={styles.settleUnbindTips}>{this.$t("settleUnbindTips")}</p>
          )}
          {this.settleUnbindForm.settlementStatus !== 1 && (
            <div slot="footer" class="dialog-footer">
              <Button
                class="confirmDialogBtn"
                onClick={this.settleUnbindSubmit}
              >
                {this.$t("MyFollowers_confirm")}
              </Button>
            </div>
          )}
        </Dialog>
      </div>
    );
  },
};

export default SignalBaseInfo;
