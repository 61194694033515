import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
import { mapState } from "vuex";
import ComplexTable from "@/shared/components/ComplexTable";
import { fieldTypeMap } from "@/shared/components/SchemaForm";

const SignalHistoricalProfitPc = {
  data() {
    return {
      total: 0,
    };
  },
  computed: {
    ...mapState(["checkPortfolio"]),
  },
  methods: {
    getSearchFormDataSource() {
      return [
        // 开始时间
        {
          name: "settleBeginTime",
          label: this.$t("startSelectTime"),
          fieldType: fieldTypeMap.datePicker,
        },
        // 结算时间
        {
          name: "settleEndTime",
          label: this.$t("settleSelectTime"),
          fieldType: fieldTypeMap.datePicker,
        },
        // 客户MT4账号
        {
          name: "followAccount",
          label: this.$t("customerMt4Account"),
          fieldType: fieldTypeMap.textInput,
        },
        // 客户账号
        {
          name: "loginId",
          label: this.$t("MyFollowers_CopyAccount"),
          fieldType: fieldTypeMap.textInput,
        },
      ];
    },
    getTableData(params) {
      const {
        settleBeginTime,
        settleEndTime,
        followAccount,
        loginId,
        pageIndex,
        pageSize,
      } = params;

      const resultParams = {
        type: 1,
        portfolioId: this.checkPortfolio.id,
        settleBeginTime: settleBeginTime,
        settleEndTime: settleEndTime,
        followAccount: followAccount ? followAccount.trim() : undefined,
        loginId: loginId ? loginId.trim() : undefined,
        pageIndex: pageIndex,
        pageSize: pageSize,
      };
      return this.$axios
        .post("/api/ShareProfit/GetSignalShareProfitPage", resultParams)
        .then((res) => {
          this.total = res.data.result.total;
          return Promise.resolve(res);
        });
    },
  },

  render() {
    const searchFormDataSource = this.getSearchFormDataSource();

    const columns = [
      // 序号
      {
        prop: "sortNum",
        label: this.$t("MyFollowers_Number"),
        width: 80,
      },
      // 开始时间
      {
        prop: "settlementBeginTime",
        label: this.$t("startSelectTime"),
        width: 150,
      },
      // 结算时间
      {
        prop: "settlementEndTime",
        label: this.$t("settleSelectTime"),
        width: 150,
      },
      // 获得分润
      {
        prop: "profit",
        label: this.$t("getProfitShare"),
        scopedSlots: {
          default: ({ row }) => {
            return formatNumberFractionDigits(row.profit);
          },
        },
      },
      // 分润订单号
      {
        prop: "tmdFinanceFlowId",
        label: this.$t("profitSharingOrderNumber"),
        scopedSlots: {
          default: ({ row }) => {
            return row.tmdFinanceFlowId || "-";
          },
        },
      },
      // 客户账号
      {
        prop: "followLoginId",
        label: this.$t("MyFollowers_CopyAccount"),
      },
      // 客户MT4账号
      {
        prop: "followAccount",
        label: this.$t("customerMt4Account"),
      },
      // 期初净值
      {
        prop: "beginPeriodEquity",
        label: this.$t("InitialNetValue"),
        scopedSlots: {
          default: ({ row }) => {
            return formatNumberFractionDigits(row.beginPeriodEquity);
          },
        },
      },
      // 本期入金
      {
        prop: "currentPeriodDeposit",
        label: this.$t("CurrentDeposit"),
        scopedSlots: {
          default: ({ row }) => {
            return formatNumberFractionDigits(row.currentPeriodDeposit);
          },
        },
      },
      // 结算净值
      {
        prop: "settlementPeriodEquity",
        label: this.$t("NetSettlementValue"),
        scopedSlots: {
          default: ({ row }) => {
            return formatNumberFractionDigits(row.settlementPeriodEquity);
          },
        },
      },
      // 本期交易量
      {
        prop: "currentPeriodVolumes",
        label: this.$t("CurrentTradingVolume"),
        scopedSlots: {
          default: ({ row }) => {
            return `${formatNumberFractionDigits(
              row.currentPeriodVolumes
            )} lots`;
          },
        },
      },
    ];

    return (
      <ComplexTable
        schemaFormProps={{
          initialValues: {
            settleBeginTime: undefined,
            settleEndTime: undefined,
            followAccount: this.$route.query.account
              ? this.$route.query.account
              : "",
            loginId: "",
          },
          dataSource: searchFormDataSource,
        }}
        columns={columns}
        requestApi={this.getTableData}
      />
    );
  },
};

export default SignalHistoricalProfitPc;
