<template>
    <div class="SignalList">
        <el-select v-model="value">
            <el-option v-for="item in options" :key="item.id" :label="item.portfolioName" :value="item.id">
            </el-option>
        </el-select>

    </div>
</template>
  
<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { concatParams, concatTableData } from "../../plugins/common.js";


export default {
    name: 'language',
    computed: {
        ...mapState(['lang', 'mobileFlag'])
    },
    data() {
        return {
            value: '',
            options:[]
        }

    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.tableData = []
            var params = {
                pageIndex: 1,
                pageSize: 100
            }
            this.$axios.get('/Api/Portfolio/GetList' + concatParams(params)).then((res) => {
                let dt = res.data
                if (dt.isSuccess) {
                    this.options = dt.result.pageList
                }
            })
        },
    }
}
</script>
  
<style lang="less" scoped>
.SignalList {
    width: 120px;
    height: 100%;
    
   

}




</style>
  