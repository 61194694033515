<template>
  <div class="reFill MobileNoCollapsIcon">
    <!-- <el-button @click="addMain(true)" class="pageTitleBtn"
        >{{ $t("MyFollowers_AddNewMasterAccount") }}
        <i class="el-icon-plus el-icon--right"></i
      ></el-button> -->
      <!-- <div class="refreshListBtnDiv">
      
          <el-button class="confirmDialogBtn" @click="refresh()" icon="el-icon-refresh-right">
              {{
                  $t("ManualReplenishment_refresh")
              }}</el-button>
      </div> -->
      <div class="bscroll" ref="bscroll">
          <div class="bscroll-container">
    <div class="tableDiv">
      <div class="mainList">
        <p>{{ $t('MyFollowers_MasterAccountList') }}</p>
        <div class="mainContent">
          <div v-if="mobileFlag" class="currentMain" @click="currentClick()">
            <p class="currentMainP">
              <svg-icon class="blackIcon" icon-class="PersonIcon"></svg-icon>
              <span>
                {{ chooseMain }}
              </span>
            </p>
            <i class="el-icon-arrow-right currentMainI"></i>
          </div>
          <el-input v-if="!mobileFlag" class="mainSearch" :placeholder="$t('searchMasterAccount')"
            v-model="searchAccount">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <ul v-if="!mobileFlag">
            <li v-for="(item, index) in mainList" :class="chooseMain == item ? 'mainActive' : ''"
              @click="mainClick(item)">
              <svg-icon class="blackIcon" icon-class="PersonIcon"></svg-icon>
              <span>
                {{ item }}
              </span>
              <b v-if="chooseMain == item">

                {{ $t('currentTxt') }}
              </b>
            </li>
          </ul>
        </div>
      </div>
      <div class="followList">
        <p v-if="!mobileFlag">{{ $t('MyFollowers_CopyAccountList') }}</p>
        <el-table v-if="!mobileFlag" v-loading="tableLoading" :data="tableData" border style="width: 100%">
          <el-table-column :fixed="tableData.length !== 0 ? true : false" width="120" prop="account"
            :label="$t('ManualReplenishment_CopyAccount')">
          </el-table-column>
          <el-table-column width="120" prop="positionLostOrderCount">
            <template slot="header" slot-scope="scope">
              <div class="tableHeaderDiv label-box">
                <span>{{ $t("ManualReplenishment_Hold_LostorderNumber") }} </span>
                <el-tooltip v-if="lang == 'en'" class="item" effect="dark"
                  :content="$t('ManualReplenishment_Hold_LostorderNumber1')" placement="top">
                  <a href="javascript:;"
                    @click.stop="labelIconClick($t('ManualReplenishment_Hold_LostorderNumber'), $t('ManualReplenishment_Hold_LostorderNumber1'))">

                    <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                  </a>

                </el-tooltip>
                <!-- <a href="javascript:;"
                    v-if="mobileFlag"
                    @click.stop="labelIconClick($t('ManualReplenishment_Hold_LostorderNumber'), $t('ManualReplenishment_Hold_LostorderNumber1'))">

                    <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                  </a> -->
              </div>
            </template>
            <template slot-scope="scope">
              <div v-if="scope.row.positionLostOrderCount !== 0" class="countClick" @click="countClick(1, scope.row)">
                {{ scope.row.positionLostOrderCount }}
              </div>
              <div v-if="scope.row.positionLostOrderCount == 0">
                {{ scope.row.positionLostOrderCount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" prop="historyLostOrderCount"
            :label="$t('ManualReplenishment_Close_LostorderNumber')">
            <template slot="header" slot-scope="scope">
              <div class="tableHeaderDiv label-box">
                <span>{{ $t("ManualReplenishment_Close_LostorderNumber") }} </span>
                <el-tooltip v-if="lang == 'en'" class="item" effect="dark"
                  :content="$t('ManualReplenishment_Close_LostorderNumber1')" placement="top">
                  <!-- <el-button>上左</el-button> -->
                  <!-- <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon> -->
                  <a href="javascript:;"
                    @click.stop="labelIconClick($t('ManualReplenishment_Close_LostorderNumber'), $t('ManualReplenishment_Close_LostorderNumber1'))">

                    <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                  </a>

                </el-tooltip>
                <!-- <a href="javascript:;"
                v-if="mobileFlag"
                    @click.stop="labelIconClick($t('ManualReplenishment_Close_LostorderNumber'), $t('ManualReplenishment_Close_LostorderNumber1'))">

                    <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                  </a> -->
              </div>
            </template>
            <template slot-scope="scope">
              <div v-if="scope.row.historyLostOrderCount !== 0" class="countClick" @click="countClick(2, scope.row)">
                {{ scope.row.historyLostOrderCount }}
              </div>
              <div v-if="scope.row.historyLostOrderCount == 0">
                {{ scope.row.historyLostOrderCount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="equity" :label="$t('ManualReplenishment_Equity')">
            <template slot-scope="scope">
              <div>
                {{ scope.row.equity ?formatNumberFractionDigits(scope.row.equity)  : '0' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="profitPercent">
            <template slot="header" slot-scope="scope">
              <div class="tableHeaderDiv label-box">
                <span>{{ $t("ManualReplenishment_RateOfReturn") }} </span>
                <el-tooltip v-if="!mobileFlag" class="item" effect="dark" :content="$t('ManualReplenishment_DEMO1')"
                  placement="top">
                  <!-- <el-button>上左</el-button> -->
                  <!-- <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon> -->
                  <a href="javascript:;"
                    @click.stop="labelIconClick($t('ManualReplenishment_RateOfReturn'), $t('ManualReplenishment_DEMO1'))">

                    <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                  </a>

                </el-tooltip>
                <a href="javascript:;" v-if="mobileFlag"
                  @click.stop="labelIconClick($t('ManualReplenishment_RateOfReturn'), $t('ManualReplenishment_DEMO1'))">

                  <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                </a>
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                {{ scope.row.profitPercent ?formatNumberFractionDigits(scope.row.profitPercent)  + "%" : '0' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="130" prop="depositPercent" :label="$t('ManualReplenishment_PrepaymentRatio')">
            <template slot-scope="scope">
              <div>
                {{ scope.row.depositPercent ? formatNumberFractionDigits(scope.row.depositPercent) + "%" : '0' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="240" :fixed="tableData.length !== 0 ? 'right' : false" v-if="!mobileFlag"
            :label="$t('OneclickReplenish')">
            <template slot-scope="scope">
              <div class="operateBtns">
                <el-button v-if="scope.row.positionLostOrderCount !== 0" @click="refillAll(scope.row, 2)" type="text"
                  size="small">
                  <svg-icon class="greenIcon" icon-class="RefillIcon"></svg-icon>
                  {{ $t("OpenPosition") }}
                </el-button>
                <el-button v-if="scope.row.historyLostOrderCount !== 0" @click="refillAll(scope.row, 4)" type="text"
                  size="small">
                  <svg-icon class="greenIcon" icon-class="RefillIcon"></svg-icon>
                  {{ $t("ClosedPosition") }}
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="90" :fixed="tableData.length !== 0 ? 'right' : false" v-if="mobileFlag"
            :label="$t('OneclickReplenish')">
            <template slot-scope="scope">
              <div class="operateBtns">
                <el-popover v-if="scope.row.historyLostOrderCount !== 0 || scope.row.positionLostOrderCount !== 0"
                  :popper-class="['longPopover', lang == 'en' ? 'refillPopover' : '']" placement="bottom-end" trigger="click">
                  <el-button v-if="scope.row.positionLostOrderCount !== 0" @click="refillAll(scope.row, 2)" type="text"
                    size="small">
                    <svg-icon class="greenIcon" icon-class="RefillIcon"></svg-icon>
                    {{ $t("OpenPosition") }}
                  </el-button>
                  <el-button v-if="scope.row.historyLostOrderCount !== 0" @click="refillAll(scope.row, 4)" type="text"
                    size="small">
                    <svg-icon class="greenIcon" icon-class="RefillIcon"></svg-icon>
                    {{ $t("ClosedPosition") }}
                  </el-button>
                  <i slot="reference" class="mobileSetting el-icon-setting"></i>

                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
        
        <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')"
          :next-text="$t('MyFollowers_NexPage')" :current-page="currentPage" @current-change="handleCurrentChange"
          @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 50, 100]" :page-size="pageSize"
          layout="prev, pager, next, sizes" :total="total">
        </el-pagination>
      </div>
      <div class="mobileTableList" v-show="mobileFlag">
                
                <el-collapse accordion >
                    <el-collapse-item v-for="(item,index) in tableData" :key="index">
                    <template slot="title">
                      <div class="mobileTableCard" @click.stop="showCollapse()">
                        
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('ManualReplenishment_CopyAccount') }}
                          </p>
                            <p class="mobileTableContent" >
                              {{ item.account }}
                            </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('ManualReplenishment_Hold_LostorderNumber') }}
                          </p>
                          <p class="mobileTableContent mobileUnderline" v-if="item.positionLostOrderCount !== 0"  @click="countClick(1, item)">
                          
                              {{ item.positionLostOrderCount }}
                          </p>
                          <p class="mobileTableContent" v-else>
                            
                            
                              {{ item.positionLostOrderCount }}
                          </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('ManualReplenishment_Close_LostorderNumber') }}
                          </p>
                          <p class="mobileTableContent mobileUnderline" v-if="item.historyLostOrderCount !== 0"  @click="countClick(2, item)">
                          
                              {{ item.historyLostOrderCount }}
                          </p>
                          <p class="mobileTableContent" v-else>
                            
                            
                              {{ item.historyLostOrderCount }}
                          </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('ManualReplenishment_Equity') }}
                          </p>
                          <p  class="mobileTableContent">
                            {{
                              item.equity ?formatNumberFractionDigits(item.equity)  : '0' 
                            }}
                          </p>
                          </div>
                          <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('ManualReplenishment_RateOfReturn') }}
                            </p>
                            <p class="mobileTableContent" >
                              {{  item.profitPercent ?formatNumberFractionDigits(item.profitPercent)  + "%" : '0'  }} 
                            </p>
                            </div>
                            <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('ManualReplenishment_PrepaymentRatio') }}
                            </p>
                            <p :class="[item.profit > 0?'greenNum':'redNum','mobileTableContent']">
                              {{ item.depositPercent ?formatNumberFractionDigits(item.depositPercent)  + "%" : '0' }}
                            </p>
                            </div>
                      </div>
                      <div class="mobileTableBtns" @click.stop="showCollapse()">
                        
                            <el-button class="mobileBlueBtn" v-if="item.positionLostOrderCount !== 0" @click="refillAll(item, 2)" type="text"
                              size="small">
                              <svg-icon class="greenIcon" icon-class="RefillIcon"></svg-icon>
                              {{ $t("OpenPosition") }}
                            </el-button>
                            <el-button class="mobileBlueBtn" v-if="item.historyLostOrderCount !== 0" @click="refillAll(item, 4)" type="text"
                              size="small">
                              <svg-icon class="greenIcon" icon-class="RefillIcon"></svg-icon>
                              {{ $t("ClosedPosition") }}
                            </el-button>
                      </div>
                    </template>
                </el-collapse-item>
                
                </el-collapse>
                <div class="x" v-if="up && tableData.length < total">
                      <i  class="el-icon-loading "></i>
                      {{ $t('loading') }}
                    </div>
                    <div class="x" v-if="!up && tableData.length >= total">
                      
                      {{ $t('noMoreData') }}
                    </div>
            </div>
        </div>
      </div>
    </div>
    <el-drawer :title="$t('ManualReplenishment_MasterAccount')" class="drawerCommon" :append-to-body="true"
      :close-on-press-escape="false" :visible.sync="mainAccountDrawer" direction="rtl">
      <div class="drawerContent">
        <div class="mainContent">
          <el-input class="mainSearch" :placeholder="$t('searchMasterAccount')" v-model="searchAccount">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <ul>
            <li v-for="(item, index) in mainList" :class="chooseMain == item ? 'mainActive' : ''"
              @click="mainClick(item)">
              <svg-icon class="blackIcon" icon-class="PersonIcon"></svg-icon>
              <span>
                {{ item }}
              </span>
              <b v-if="chooseMain == item">
                {{ $t('currentTxt') }}

              </b>
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
    <div class="mobileLabelTips" ref="mobileLabelTips" v-if="isLableTips">
      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
      <p>
        {{ labelTitle }}
      </p>
      <div>
        {{ labelTxt }}
      </div>
    </div>
    <!-- <PopupTips :popupVisible='isPopupVisible' :icon="iconImg" :title="$t('MyFollowers_prompt')"
      @confirmPopupTips="confirmPopup" @cancelPopupTips="cancelPopup" ref="popupTipsRef" :content="infoTitle"
      :isHtml="false" :btnType="2" /> -->
      <el-dialog width="324px" class="resetPassword" :visible.sync="isPopupVisible" center :close-on-click-modal="false" :show-close="false" >
          <div slot="title">
              <img src="../../assets/images/NotifyWarning.svg" alt="">
              <p class="popupMouduleTitle">
                  {{ $t('refillPopTitlte') }}
              </p>
          </div>
          <el-form   class="forgetForm" ref="changeForm" :rules="changeRules" label-position="top" label-width="80px" :model="changeForm">
              <el-form-item :label="$t('refillPopContent')" prop="cwPassword" style="margin-bottom: 10px;">
                  <el-input v-model="changeForm.cwPassword" autocomplete="off"  type="password" show-password></el-input>
              </el-form-item>
          </el-form>
          <p class="closeTips">
            {{ $t('refillcloseAllOrderTips') }}
          </p>
          <span slot="footer" class="btnReset" >
              <el-button class="cancelGray" @click="cancelPopup()">{{ $t('MyFollowers_Cancel') }}</el-button>
              <el-button class="confirmBlue" v-loading.fullscreen.lock="fullscreenLoading" @click="confirmPopup()">{{ $t('checkBtnTxt')
              }}</el-button>
          </span>
      </el-dialog>
  </div>
</template>
  
<script>
// @ is an alias to /src
import { concatParams, concatTableData } from "../../plugins/common.js";
import { mapState } from "vuex";
import PopupTips from '@/components/moudule/PopupTips.vue'
import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'
  import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
  
  BScroll.use(Pullup)

export default {
  name: "ManualRefill",
  components: {
    PopupTips
  },
  computed: {
    ...mapState(["checkPortfolio", "mobileFlag", "refillMainAccount", "lang"]),
  },
  data() {
    var checkPercent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("MyFollowers_TradeRatioNotNull")));
      } else {
        var reg = /^(100|([1-9][0-9]?)|(0|[1-9][0-9]?)(\.[\d]{1,2}))$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t("MyFollowers_checkPercentNotF")));
        } else {
          callback();
        }
      }
    };
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      pageSize: 5,
      mainAccountDrawer: false,
      fullscreenLoading: false,
      mainForm: {
        activation: false,
        followDirection: 0,
        standardisation: 100,
        account: "",
      },
      rules: {
        standardisation: [
          { validator: checkPercent, trigger: "blur" },
          // { type: 'number', message: this.$t('MyFollowers_TradeRatioMustNum') },
        ],
      },
      checkRow: {},
      accountList: [],
      mainAccountDrawerTitle: "",
      accountDisabled: false,
      labelTitle: '',
      labelTxt: '',
      isLableTips: false,
      mainList: [],
      searchAccount: '',
      chooseMain: '',
      tableLoading: false,
      isPopupVisible: false,
      iconImg: require('../../assets/images/NotifyWarning.svg'),
      infoTitle: '',
      checkType: 2,
      changeForm:{
        cwPassword:'',
      },
      changeRules: {
        cwPassword: [
          {  required: true, message: this.$t('MyFollowers_Mt4PasswordNotNull1'), trigger: 'blur' },
          // { type: 'number', message: this.$t('MyFollowers_TradeRatioMustNum') },
        ],
      },
      up:false
    };
  },
  watch: {
    searchAccount(curVal, oldVal) {
      // console.log("curVal:", curVal)
      // 实现input连续输入，只发一次请求
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        // console.log("searchAccount:", this.searchAccount)
        if (this.searchAccount != '') {
          //为调用后端接口赋值  
          // this.queryForm.zjhm = this.sfzhm
          // console.log("baseForm.zjhm:", this.queryForm.zjhm)
          this.getMainList()
        } else {
          this.searchAccount = 0
          this.getMainList()
        }
      }, 1000)
    }
  },
  created() {
    this.getMainList()
    this.screenClick()

  },
  methods: {
    formatNumberFractionDigits,
    refresh(){
      if(this.chooseMain){
        this.getData(this.currentPage,this.pageSize)
      }else{
        this.$notify.warning({title: this.$t('MyFollowers_prompt'), message:this.$t('pleseSelectMainAccount')});
      }
    },
    showCollapse(){},
    scrollFn(){
        this.$nextTick(() => {
         
            if (!this.scroll) {
                this.scroll = new BScroll(this.$refs.bscroll, {
                    click: true,
                    scrollY: true,
                    probeType: 3
                });
            }else{
                this.scroll.refresh();
            }
            
            //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
            this.scroll.on('touchEnd', (pos) => {
                //上拉加载 总高度>下拉的高度+10 触发加载更多
                if(this.scroll.maxScrollY>pos.y + 50){
                    // console.log("加载更多")
                    this.up=true;
                    setTimeout(()=>{
                    //使用refresh 方法 来更新scroll  解决无法滚动的问题
                        if(this.tableData.length < this.total){
                          this.currentPage = this.currentPage + 1
                          this.getData(this.currentPage,this.pageSize,'noRefresh')
                          this.scroll.refresh();
                          this.up=false;
                        }else{
                          this.up=false;
                        }
                        
                   },1000) 
                   
                }
                // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
            })
          
            // console.log(this.scroll.maxScrollY)
        });
    },
    getData(pageIndex, pageSize,tag) {

      this.tableLoading = true
      if(!this.mobileFlag || tag !== 'noRefresh'){
        this.tableData = [];
      }
      var params = {
        account: this.chooseMain,
        pageIndex: pageIndex,
        pageSize: pageSize,
      };
      this.$axios
        .get("/Api/Recon/SubAccount" + concatParams(params))
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.total = dt.result.total;
            if(this.mobileFlag && tag == 'noRefresh'){
              dt.result.data.forEach((item) =>{
                this.tableData.push(item)
              })
              
              
            }else{
              this.tableData = concatTableData(
                dt.result.data,
                dt.result.total,
                dt.result.pageIndex,
                this.pageSize
              );
            }
            
            this.fullscreenLoading = false;
            if(this.mobileFlag){
              this.scrollFn()
            }
          }
          else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }

        }).finally(() => {
          this.tableLoading = false;
        });
    },
    getMainList() {
      var params = {
        portfolioId: this.checkPortfolio.id,
        account: Number(this.searchAccount)
      };
      this.$axios.get("/Api/Recon/MainAccount" + concatParams(params)).then((res) => {
        let dt = res.data;
        if (dt.isSuccess) {
          // this.mainAccountDrawer = true
          this.mainList = dt.result;
          // this.signalForm.portfolioName = dt.result.portfolioName
          // this.tableData = concatTableData(dt.result,10)
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData(this.currentPage, this.pageSize);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData(this.currentPage, this.pageSize);
    },
    mainClick(item) {
      // debugger
      this.$store.commit("setRefillMainAccount", item);
      this.chooseMain = item
      if (this.mobileFlag) {
        this.mainAccountDrawer = false
      }
      this.currentPage = 1
      this.getData(1, this.pageSize)
    },
    countClick(tag, row) {
      this.$store.commit("setRefillSubAccount", row.account);
      this.$store.commit("setRefillType", tag);
      this.$store.commit("setRefillData", row);
      this.$router.push({ path: '/RefillDetail' })
    },
    currentClick() {
      this.mainAccountDrawer = true
    },
    labelIconClick(title, content) {
      if (this.mobileFlag) {
        this.isLableTips = !this.isLableTips;
        this.labelTitle = title;
        this.labelTxt = content;
      } else {
        this.isLableTips = false;
      }
    },
    screenClick() {
      // debugger
      document.addEventListener("click", (e) => {
        if (this.$refs.mobileLabelTips) {
          let self = this.$refs.mobileLabelTips.contains(e.target);
          if (!self) {
            this.isLableTips = false;
          }
        }

      });
    },
    refillAll(row, type) {
      if (type == 2) {
        this.infoTitle = this.$t("ManualReplenishment_refillAllConfirm")
      } else {
        this.infoTitle = this.$t("ManualReplenishment_refillAllConfirmClose")
      }
      this.checkType = type
      this.checkRow = row
      this.isPopupVisible = true
      

    },
    cancelPopup(val) {
      this.isPopupVisible = val
      this.$refs.changeForm.resetFields()
    },
    confirmPopup(val) {
      this.isPopupVisible = val
      this.fullscreenLoading = true;
      var params = {
        orderList: [],
        cwPassword:this.changeForm.cwPassword,
        reconOrderType: this.checkType,
        followUnique: this.checkRow.followUnique,
        mainUnique: this.checkRow.mainUnique,
      };
      this.$axios
        .post("/Api/Recon/ReconOrders", params)
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess1')});

            this.getData(this.currentPage,this.pageSize);
          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        })
        .finally(() => {
          this.fullscreenLoading = false;
          this.$refs.changeForm.resetFields()
        });
    },
  },
};
</script>
<style lang="less" scoped>
.reFill {
  position: relative;

  .tableDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    .refillTitle {
      font-family: 'PingFangSC-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;

      /* 正文黑 */

      color: #212121;
    }

    .mainList {
      width: 28%;
      p{
        font-size: 14px;
        font-weight: 600;
        font-family: 'PingFangSC-Medium';
        color: #212121;
        margin: 0 0 12px 0;
      }

    }

    .followList {
      width: 70%;
      p{
        font-size: 14px;
        font-weight: 600;
        font-family: 'PingFangSC-Medium';
        color: #212121;
        margin: 0 0 12px 0;

      }
      .countClick {
        cursor: pointer;
        color: #3b9eee;
      }
    }
  }

}

.mainContent {
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  width: calc(100% - 12px);
  padding-bottom: 26px;

  // padding: 6px;
  .mainSearch {
    margin: 6px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 10px 0 10px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      span {
        font-family: 'PingFangSC-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        /* 正文黑 */

        color: #212121;

      }
    }

    .mainActive {
      background: rgba(220, 73, 70, 0.1);

      span {
        color: #E35972;
      }

      .svg-icon {
        color: #E35972;
      }

      b {
        font-family: 'PingFangSC-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        /* identical to box height */
        color: #FFFFFF;
        background: #E35972;
        border-radius: 4px;
        padding: 2px;
        margin-left: 4px;
      }
    }
  }
}
.resetPassword{
    .popupMouduleTitle{
        color:  #333;
        text-align: center;
        font-family: 'PingFangSC-Medium';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 20px auto;
    }
    .btnReset{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
    }
  }
  .popupMouduleTitle{
    color:  #333;
    text-align: center;
    font-family: 'PingFangSC-Semibold';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .popupMouduleContent{
    color:  #333;
text-align: center;
font-family: PingFang SC;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 20px auto;
  }
  .cancelGray,.confirmBlue{
            border-radius: 4px;
            background:  #E5E5E5;
            color:  #333;
            font-family: 'PingFangSC-Regular';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border: 0;
            padding: 10px 30px;

        }
        .confirmBlue{
            background: #4D71FF;
            color: #FFF;
        }
.label-box {
  justify-content: center;
}
.closeTips{
  text-align: left;
  color: #E35972;
  margin-bottom: 30px;
  margin-top: 25px;
}
.operateBtns {
  padding-left: 0;
}
.refreshListBtnDiv{
  position: absolute;
  right: 0;
  top: -155px;
}
@media screen and (max-width:769px) {
  .reFill {
  height: 100%;
    .tableDiv {
      // margin-top: 50px;
      padding-top: 0!important;
      display: flex;
      flex-direction: column;
      background: transparent;
      .mainList {
        width: 100%;
        padding-top: 0;
        .mainContent {
          padding: 0;
          width: 100%;
          margin-bottom: 20px;
          .currentMain {
            padding: 11px 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-radius: 8px;
            // border: 1px solid  #E5E5E5;
            background: #FFF;
            .currentMainP {
              margin: 0;
              display: flex;
              flex-direction: row;
            }

            .currentMainI {
              font-size: 20px;
            }
          }
        }
      }

      .followList {
        width: 100%;
      }
    }


  }

  .drawerContent {
    .mainContent {
      padding: 20px;
      margin: 0;
      width: calc(100% - 40px);
      border: 0;
    }
  }
  .mainList{
    padding-top: 20px;
  }
  .refreshListBtnDiv{
    position: absolute;
    left: 0;
    top: 10px!important;
    .confirmDialogBtn{
        padding: 8px ;
        font-size: 12px;
        line-height: 15px;
      }
    }
}

</style>
  