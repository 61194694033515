<template>
  <div class="MyAccount">
    <p class="pageTop" v-if="!mobileFlag">
      <span class="signalTitle" v-show="$route.path == '/MyAccount'">
        {{ checkPortfolio.portfolioName + $t("MyFollowers_xSignalSource") }}
        <div
          class="refreshListBtnDiv"
          v-show="['follow', 'refill'].includes(activeName)"
        >
          <el-button class="confirmDialogBtn" @click="refresh()">
            {{ $t("ManualReplenishment_refresh") }}
            <i class="el-icon-refresh-right"></i>
          </el-button>
        </div>
      </span>
    </p>

    <div class="PageCommon">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('baseInfo')" name="signalBaseInfo" >
          <signal-base-info v-if="activeName === 'signalBaseInfo'" />
        </el-tab-pane>
        <el-tab-pane :label="$t('MyFollowers_CopyAccountList')" name="follow" v-if="checkPortfolio.status == 1">
          <follow-account ref="followAccount" @GoHistory="goHistoryProfit" />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('ManualReplenishment_ManualReplenishment')"
          name="refill"
        >
          <manual-refill ref="refill" />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('historicalProfit')"
          name="signalHistoricalProfit"
        >
          <SignalHistoricalProfit
            v-if="activeName == 'signalHistoricalProfit'"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FollowAccount from "@/views/pages/FollowAccount.vue";
import ManualRefill from "@/views/pages/ManualRefill.vue";
import SignalList from "@/components/common/SignalList.vue";
import SignalBaseInfo from "@/views/pages/SignalDetail/SignalBaseInfo";
import SignalHistoricalProfit from "@/views/pages/SignalDetail/SignalHistoricalProfit";

export default {
  name: "MyAccount",
  components: {
    FollowAccount,
    ManualRefill,
    SignalList,
    SignalBaseInfo,
    SignalHistoricalProfit,
  },
  computed: {
    ...mapState(["checkPortfolio", "activeTab", "mobileFlag"]),
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      pageSize: 5,
      tableSearchForm: {
        Account: "",
        DateRange: "",
      },
      activeName: "signalBaseInfo",
    };
  },
  created() {
    this.activeName = this.activeTab;
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab)
      this.$store.commit("setActiveTab", tab.name);
      if (tab.name == "follow") {
        this.$refs.followAccount.getData(1, 5);
      } else if (tab.name == "refill") {
        this.$refs.refill.getMainList();
      }
    },
    refresh() {
      if (this.activeName == "follow") {
        this.$refs.followAccount.getData(1, 5);
      } else if (this.activeName == "refill") {
        this.$refs.refill.getMainList();
      }
    },
    goHistoryProfit(name,account){
      this.activeName = name
      this.$router.push({ path: '/MyAccount', query: { account } });
    }
  },
};
</script>
<style lang="less" scoped>
.MyAccount {
  .signaListPosition {
    position: absolute;
    right: 10px;
    top: 4px;
  }
  .PageCommon {
    padding-top: 0;
  }
  .pageTop {
    .signalTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      .refreshListBtnDiv {
        position: relative;
        margin-left: 10px;
        .confirmDialogBtn {
          color: #fff;
          text-align: center;
          font-family: "PingFangSC-Medium";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          padding: 8px 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .MyAccount {
    .PageCommon {
      padding-top: 20px;
    }
  }
}
</style>
