import isMobile from "@/shared/utils/isMobile";
import H5 from "./h5";
import Pc from "./pc";

const SignalHistoricalProfit = {
  render() {
    return isMobile ? <H5 /> : <Pc />;
  },
};

export default SignalHistoricalProfit;
